import { ButtonType } from 'components/atoms/Button';
import { IconProps } from 'components/atoms/Icon';

export const page404Content = {
  mainTitle: '404',
  heading: "We can't find that page.",
  subheading: "Sorry, the page you are looking for doesn't exist or has been moved.",
  button: {
    link: '/',
    label: 'Take me home',
  },
  tiles: [
    {
      id: 1,
      icon: { name: 'error-404-contact' } as IconProps,
      title: 'Contact us',
      description: 'Any questions we can answer for you?',
      tileButton: {
        link: '/contact-us',
        label: "Let's talk",
        variant: 'tertiary' as ButtonType['variant'],
        icon: { name: 'common-arrow-right-black' } as IconProps,
      },
    },
    {
      id: 2,
      icon: { name: 'error-404-blog' } as IconProps,
      title: 'Our blog',
      description: 'Check out the latest articles and resources.',
      tileButton: {
        link: '/blog',
        label: 'View latest posts',
        variant: 'tertiary' as ButtonType['variant'],
        icon: { name: 'common-arrow-right-black' } as IconProps,
      },
    },
  ],
};
