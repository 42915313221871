import React from 'react';

import Button from 'components/atoms/Button';
import GlassTile from 'components/atoms/GlassTile';
import Icon from 'components/atoms/Icon';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';
import Layout from 'components/templates/Layout';

import { page404Content } from './404.constants';
import {
  Styled404Content,
  Styled404Links,
  StyledBigTypography,
  StyledContainer,
  StyledLinksWrapper,
} from './404.styles';

const { mainTitle, heading, subheading, button, tiles } = page404Content;

const NotFoundPage = () => (
  <Layout seo={{ title: 'Not found' }} isFooterTop={false} transparentHeader={false}>
    <StyledContainer pb={{ base: 0, md: 184 }} contentWidth={1440}>
      <Styled404Content>
        <StyledBigTypography
          align="right"
          fontWeight={700}
          color="gray-50"
          lineHeight={1}
          size={{ base: 200, md: 240, xl: 480 }}
        >
          {mainTitle}
        </StyledBigTypography>
        <Typography
          fontWeight={500}
          letterSpacing="-0.02em"
          padding={{ bottom: 24 }}
          asEl="h1"
          size={{ base: 36, lg: 60 }}
        >
          {heading}
        </Typography>
        <Typography fontWeight={300} padding={{ bottom: 24 }} asEl="p" size={{ base: 18, xl: 20 }}>
          {subheading}
        </Typography>
        <Button {...button} />
      </Styled404Content>
    </StyledContainer>
    <StyledLinksWrapper>
      <Container contentWidth={1440}>
        <Styled404Links>
          {tiles.map(({ icon, title, description, tileButton }) => (
            <GlassTile data-glass-variant="light">
              <Icon {...icon} />
              <Typography
                fontWeight={500}
                size={{ base: 20, lg: 24 }}
                padding={{ top: { base: 8, lg: 20 }, bottom: { base: 4, lg: 16 } }}
              >
                {title}
              </Typography>
              <Typography fontWeight={300} size={16} padding={{ bottom: { base: 12, lg: 24 } }}>
                {description}
              </Typography>
              <Button {...tileButton} />
            </GlassTile>
          ))}
        </Styled404Links>
      </Container>
    </StyledLinksWrapper>
  </Layout>
);

export default NotFoundPage;
