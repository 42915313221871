import styled from 'styled-components';
import { breakpoint } from 'styles';

import { StyledButton } from 'components/atoms/Button/Button.styles';
import { StyledGlassTile } from 'components/atoms/GlassTile/GlassTile.styles';
import Typography from 'components/atoms/Typography';
import Container from 'components/molecules/Container';

export const StyledBigTypography = styled(Typography)`
  position: absolute;
  top: 0;
  right: -50px;
  z-index: var(--z-index-zero);
  pointer-events: none;
  background: linear-gradient(90.18deg, #eef7ff -4.97%, #f6e7fe 60.42%, #f8c9ff 122.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-stroke: 12px var(--c-transparent);

  ${breakpoint.xl} {
    top: -100px;
  }
`;

export const StyledContainer = styled(Container)`
  position: relative;
  isolation: isolate;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: var(--z-index-underground);
    display: block;
    width: 100%;
    height: 120%;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
    content: '';
    background-color: var(--c-white);
  }
`;

export const Styled404Content = styled.div`
  position: relative;
  padding: 184px 0 0;

  ${breakpoint.md} {
    padding: 184px var(--gap-md) 0;
  }

  ${breakpoint.xl} {
    padding: 0 var(--gap-md);
    margin-top: 160px;
  }

  h1,
  p {
    position: relative;
    z-index: var(--z-index-ground);
  }

  & > ${StyledButton} {
    justify-content: center;
    width: 100%;
    max-width: unset;

    ${breakpoint.xs} {
      max-width: fit-content;
    }
  }
`;

export const StyledLinksWrapper = styled.div``;

export const Styled404Links = styled.div`
  z-index: var(--z-index-ground);
  display: flex;
  flex-direction: column;
  gap: var(--gap-xs);
  align-items: center;
  padding: var(--gap-xxxxxl) 0;

  ${breakpoint.md} {
    flex-direction: row;
    align-items: stretch;
    padding: 0 var(--gap-md) 80px;
  }

  ${breakpoint.xl} {
    padding-bottom: 200px;
  }

  ${StyledGlassTile} {
    position: relative;
    z-index: var(--z-index-ground);
    width: 100%;
    max-width: 405px;

    svg {
      width: 32px;
      height: 32px;

      ${breakpoint.lg} {
        width: 48px;
        height: 48px;
      }
    }

    ${StyledButton} {
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
`;
